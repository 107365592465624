.sui-layout-header {
    background-color: #f6f9fc;;
    background-image: none;
}

.header_text, div[class^="sui-"], button[class^="sui-"], div[class^="css-"]  { font-family: "Jost*",Helvetica,Arial,sans-serif; }

.sui-result__title-link {
    font-family: 'Playfair Display', serif;
}

.header_title {
    font-weight: 400;
    font-size: 26px;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
}

.header_text {
    max-width: 850px;
    margin-top: 3px;
  }
  
  .header_title {
      font-size: 38px;
      font-weight: 10;
  }
  
  .header_intro {
      font-size: 16px;
  }
  
  .header_info {
      margin-bottom: 15px;
  }
  
  .header_img_syllabus {
      margin-left:12px;
      width: 132px;
  }
  
  .header_img_museum {
      width:78px;
  }
  
  .header_img_syllabus_wrapper {
      margin-bottom: 0px;
      margin-top: 10px;
  }
  
  .header_poweredby_syllabus {
      bottom:7px;
  }
  
  .header_img_main {
      margin-top: auto;
  }
  
  .sui-layout-header__inner {
      padding: 0 24px
  }
  
  .sui-layout-header {
      padding-right: 0px;
      padding-left: 0px;
  }
    
  .header_poweredby {
      letter-spacing: 0.2px;
  }





/* JOST* */
@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-100.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-200.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-300.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-400.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-500.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-600.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-700.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-800.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-900.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-100-Italic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-200-Italic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-300-Italic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-400-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-500-Italic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-600-Italic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-700-Italic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;

}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-800-Italic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("https://indestructibletype.com/fonts/Jost-900-Italic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

  /* cyrillic */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDTbtXK-F2qC0usEw.woff) format('woff');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDYbtXK-F2qC0usEw.woff) format('woff');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDZbtXK-F2qC0usEw.woff) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDXbtXK-F2qC0s.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }